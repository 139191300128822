import Alpine from "alpinejs"
window.Alpine = Alpine

Alpine.data("form", form)
Alpine.start()

function form() {
  return {
    formData: {
      name: "",
      nameservers: "",
    },
    message: "",

    submit() {
      this.message = ""
      this.formData.nameservers = this.formData.nameservers.split(",")
      fetch("/api/post", {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(this.formData),
      }).then((resp) =>
        resp.json().then((resp) => {
          this.message = JSON.stringify(resp, null, 2)
        }),
      )
    },
  }
}
